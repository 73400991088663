/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DatePicker, notification, Button, Card, Col, Form, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import PageContentHeader from "../../../components/common/PageContentHeader";
import {
  getRetailReports,
  getBookingReports,
  getOverAllReports,
  getExchangeReports,
  getHomeVisitTestDriveReports,
} from "../../../services/master-services";
import { API_RESPONSE_CODES } from "../../../utills/constant";
import Loader from "../../../components/common/Loader";
import dayjs from "dayjs";
import InputSelect from "../../../components/common/InputSelect";

const { RangePicker } = DatePicker;

const ReportsComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState("");
  const [reportData, setReportData] = useState([]);

  const [form] = Form.useForm();

  const handleReportName = (value) => {
    setReportData(value);
  };

  useEffect(() => {
    if (reportData === "home_visit_test_drive_reports") {
      setReportType("Home Visit/Test Drive Reports");
    } else if (reportData === "retail_reports") {
      setReportType("Retail Reports");
    } else if (reportData === "exchange_reports") {
      setReportType("Exchange Reports");
    } else if (reportData === "booking_reports") {
      setReportType("Booking Reports");
    } else if (reportData === "over_all_reports") {
      setReportType("Over All Reports");
    }
    form.setFieldsValue({ date: [currentMonthStart, currentMonthEnd] });
  }, [reportData]);

  const currentMonthStart = dayjs().startOf("month");
  const currentMonthEnd = dayjs();

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const [startDate, endDate] = values && values.date;
    try {
      let response;
      if (reportData === "home_visit_test_drive_reports") {
        response = await getHomeVisitTestDriveReports(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        );
      } else if (reportData === "retail_reports") {
        response = await getRetailReports(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        );
      } else if (reportData === "exchange_reports") {
        response = await getExchangeReports(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        );
      } else if (reportData === "booking_reports") {
        response = await getBookingReports(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        );
      } else if (reportData === "over_all_reports") {
        response = await getOverAllReports(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        );
      }
      if (response.data.status === API_RESPONSE_CODES.OK) {
        const filePath = response.data.data?.path;
        const link = document.createElement("a");
        link.href = filePath;
        // link.target = '_blank';
        link.download = filePath?.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        form.setFieldsValue({
          date: [currentMonthStart, currentMonthEnd],
          report: null,
        });
        setIsLoading(false);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message
          ? error.response?.data?.message
          : error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const reportName = [
    {
      label: "OverAll Reports",
      id: "over_all_reports",
    },
    {
      label: "Booking Reports",
      id: "booking_reports",
    },
    {
      label: "Exchange Reports",
      id: "exchange_reports",
    },
    {
      label: "Retail Reports",
      id: "retail_reports",
    },
    {
      label: "Home Visit/Test Drive Reports",
      id: "home_visit_test_drive_reports",
    },
  ];

  return (
    <>
      <Loader loading={isLoading} />
      <div>
        <PageContentHeader title={`Reports ${reportType ? ' - ' + reportType : ''}`} />
        <Card className="filter-card mb-3">
          <Form
            layout="vertical"
            name="normal_search"
            className="login-form"
            initialValues={{
              remember: true,
              date: [currentMonthStart, currentMonthEnd],
            }}
            form={form}
            onFinish={handleSubmit}
          >
            <div className="login-input-field">
              <Row gutter={[24, 16]}>
                <Col xl={5} lg={12} md={12} sm={24} xs={24}>
                  <InputSelect
                    name="report"
                    rules={[
                      { required: true, message: "Please select report name" },
                    ]}
                    label="Report Name"
                    placeholder="Report Name"
                    options={reportName}
                    optionName={"label"}
                    onSelect={handleReportName}
                    allowClear
                  />
                </Col>
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="date"
                    label="Start Date/End Date"
                    className="report-date-range"
                    rules={[
                      { required: true, message: "Please select the report date range" },
                    ]}
                  >
                    <RangePicker
                      format={"DD/MM/YYYY"}
                      disabledDate={(current) => {
                        return current && current > dayjs().endOf("day");
                      }}
                      
                      className="custom-range-picker"
                    />
                  </Form.Item>
                </Col>
                <Col xl={14} lg={12} md={12} sm={24} xs={24}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="secondary-button reports-download-button"
                    icon={<DownloadOutlined />}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default ReportsComponent;
