/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, notification, Form, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { getVehicleModelsData } from '../../../../../services/dashboard-services';
import InputSelect from '../../../../../components/common/InputSelect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTargetUserHierachy, saveDashboardHomeFilters } from '../../../../auth/slice/authSlice';
import { monthData } from '../../../../../utills/constant';
import { getTerritoriesService } from '../../../../../services/master-services';

const Filters = ({ onFiltersChange, heading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const currentMonthStart = dayjs().startOf('month');
  const currentMonthEnd = dayjs();

  const [vehicleModels, setVehicleModels] = useState([]);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [dropdownIds, setDropdownIds] = useState({
    territory_id: 0,
    area_id: '',
  });

  const [selectUser, setUser] = useState();
  const [territoryData, setTerritoryData] = useState([]);
  const [lastSelectedUsers, setLastSelectedUsers] = useState([]);
  const [getUserOne, setGetUserOne] = useState([]);
  const [getUserThird, setGetUserThird] = useState([]);
  const [getUserFivth, setGetUserFivth] = useState([]);
  const [getUserFourth, setGetUserFourth] = useState([]);
  const [getUserSecond, setGetUserSecond] = useState([]);
  const [getUserSixth, setGetUserSixth] = useState([]);
  const [showThirdDropdown, setShowThirdDropdown] = useState(false);
  const [showFivthDropdown, setShowFivthDropdown] = useState(false);
  const [showSixthDropdown, setShowSixthDropdown] = useState(false);
  const [showFourthDropdown, setShowFourthDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthId());
  const [isLoading, setIsLoading] = useState(false);

  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const userDetails = useSelector((state) => state?.auth?.userData?.id);
  const selectUserData = useSelector((state) => state?.auth?.userHierarchyData);
  const launchDate = useSelector((state) => state?.auth?.userData?.launch_date);

  const data = selectUserData?.subordinate_recursive?.map((element) => element?.subordinate_role);
  const data2 = getUserOne?.map((element) => element?.subordinate_role);
  const data3 = getUserSecond?.map((element) => element?.subordinate_role);
  const data4 = getUserThird?.map((element) => element?.subordinate_role);
  const data5 = getUserFourth?.map((element) => element?.subordinate_role);
  const [selectAllUser, setAllUser] = useState({
    GM: null, // single select value
    AGM: null,
    TSM: null,
    SM: null,
    TL: null,
    SO: null,
  });

  useEffect(() => {
    getVehicleModels();
    dispatch(fetchTargetUserHierachy({ id: userDetails, isTargetFlow: false }));
    if (heading === 'Home') {
      onFiltersChange((prevFilters) => ({
        ...prevFilters,
        month_id: selectedMonth,
        year_id: selectedYear,
        startDate: currentMonthStart,
        endDate: currentMonthEnd,
      }));
    }
    if (heading !== 'Home') {
      onFiltersChange((prevFilters) => ({
        ...prevFilters,
        month_id: selectedMonth,
        year_id: selectedYear,
      }));
      getTerritoryData(5);
    }
  }, []);

  useEffect(() => {
    let dataa = null;

    // Determine data based on current selections
    if (selectAllUser.AGM === null) {
      dataa = selectAllUser?.GM;
    } else if (selectAllUser?.TSM === null) {
      dataa = selectAllUser?.AGM;
    } else if (selectAllUser?.SM === null) {
      dataa = selectAllUser?.TSM;
    } else if (selectAllUser?.TL === null) {
      dataa = selectAllUser?.SM;
    } else if (selectAllUser?.SO === null) {
      dataa = selectAllUser?.TL;
    } else {
      dataa = selectAllUser?.SO;
    }

    onFiltersChange((prevFilters) => ({
      ...prevFilters,
      userId: dataa,
      //   startDate: dates.startDate ? moment(dates.startDate).format("YYYY-MM-DD") : "",
      //   endDate: dates.endDate ? moment(dates.endDate).format("YYYY-MM-DD") : "",
    }));
    dispatch(
      saveDashboardHomeFilters((prevFilters) => ({
        ...prevFilters,
        userId: dataa,
      }))
    );
  }, [selectAllUser]);

  function getCurrentMonthId() {
    const currentMonth = new Date().getMonth() + 1;
    const month = monthData?.find((m) => m.id === currentMonth);
    return month?.id || '';
  }

  const getTerritoryData = async (state_id) => {
    setIsLoading(true);
    try {
      const territoryData = await getTerritoriesService(5, false);
      setTerritoryData(territoryData?.data?.data);
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getVehicleModels = async () => {
    try {
      const reqData = { makeId: 1 };
      const response = await getVehicleModelsData(reqData, true);
      setVehicleModels(response?.data?.data || []);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    }
  };

  const handleStartDateChange = (date) => {
    // Ensure end date is not before start date
    if (dates?.endDate?.isBefore(date)) {
      notification.error({
        description: 'Start Date cannot be after End Date.',
      });
      return;
    }

    setDates((prevDates) => ({
      ...prevDates,
      startDate: date,
    }));

    onFiltersChange((prevFilters) => ({
      ...prevFilters,
      startDate: date && date?.format('YYYY-MM-DD'),
    }));
    dispatch(
      saveDashboardHomeFilters((prevFilters) => ({
        ...prevFilters,
        startDate: date && date?.format('YYYY-MM-DD'),
      }))
    );
  };

  const disablePastDates = (current) => {
    return current < dayjs(launchDate, 'DD-MM-YYYY').startOf('month'); // Disable dates before the launch month
  };

  const handleEndDateChange = (date) => {
    // Ensure start date is not after end date
    if (date?.isBefore(dates.startDate)) {
      notification.error({
        description: 'End Date cannot be before Start Date.',
      });
      return;
    }

    setDates((prevDates) => ({
      ...prevDates,
      endDate: date,
    }));

    onFiltersChange((prevFilters) => ({
      ...prevFilters,
      endDate: date && date?.format('YYYY-MM-DD'),
    }));
  };

  const handleModelChange = (value) => {
    onFiltersChange((prevFilters) => ({
      ...prevFilters,
      modelNo: value,
    }));
    dispatch((prevFilters) => ({
      ...prevFilters,
      modelNo: value,
    }));
  };

  const firstUserOnchange = (e) => {
    if (e) {
      form.setFieldsValue({
        user2: [],
        user3: [],
        user4: [],
        user5: [],
        user6: [],
      });
      setLastSelectedUsers((state) => [...state, e]);
      setGetUserOne([]);
      let filteredData = [];

      filteredData = [...filteredData, ...selectUserData?.subordinate_recursive?.filter((element) => element.id === e)];

      let newGetUserOne = [];
      filteredData.forEach((element) => {
        newGetUserOne = [...newGetUserOne, ...element?.subordinate_recursive];
      });
      setGetUserOne(newGetUserOne);
      setShowSecondDropdown(newGetUserOne?.length > 0);
      setShowThirdDropdown(false);
      setShowFourthDropdown(false);
      setShowFivthDropdown(false);
      setShowSixthDropdown(false);

      setAllUser((prev) => ({
        ...prev,
        AGM: null,
        TSM: null,
        SM: null,
        TL: null,
        SO: null,
      }));

      setAllUser((prev) => ({
        ...prev,
        GM: e,
      }));
    } else {
      form.setFieldsValue({
        user2: [],
        user3: [],
        user4: [],
        user5: [],
        user6: [],
      });
      setShowSecondDropdown(false);
      setShowThirdDropdown(false);
      setShowFourthDropdown(false);
      setShowFivthDropdown(false);
      setShowSixthDropdown(false);
      setAllUser((prev) => ({
        ...prev,
        GM: null,
      }));
    }
  };

  const secondUserOnChange = (e) => {
    if (e) {
      form.setFieldsValue({
        user3: [],
        user4: [],
        user5: [],
        user6: [],
      });
      setLastSelectedUsers((state) => [...state, e]);
      setGetUserSecond([]);
      let filteredData = [];
      filteredData = [...filteredData, ...getUserOne?.filter((element) => element.id === e)];

      let newGetUserSecond = [];
      filteredData.forEach((element) => {
        newGetUserSecond = [...newGetUserSecond, ...element?.subordinate_recursive];
      });
      setGetUserSecond(newGetUserSecond);
      setShowThirdDropdown(newGetUserSecond?.length > 0);
      setShowFourthDropdown(false);
      setShowFivthDropdown(false);
      setShowSixthDropdown(false);

      setAllUser((prev) => ({
        ...prev,
        TSM: null,
        SM: null,
        TL: null,
        SO: null,
      }));
      setAllUser((prev) => ({
        ...prev,
        AGM: e,
      }));
    } else {
      form.setFieldsValue({
        user3: [],
        user4: [],
        user5: [],
        user6: [],
      });
      setShowThirdDropdown(false);
      setShowFourthDropdown(false);
      setShowFivthDropdown(false);
      setShowSixthDropdown(false);
      setAllUser((prev) => ({
        ...prev,
        AGM: null,
      }));
    }
  };

  const thirdUserOnchange = (e) => {
    if (e) {
      form.setFieldsValue({
        user4: [],
        user5: [],
        user6: [],
      });
      setLastSelectedUsers((state) => [...state, e]);
      setGetUserThird([]);
      let filteredData = [];
      filteredData = [...filteredData, ...getUserSecond?.filter((element) => element.id === e)];

      let newGetUserThird = [];
      filteredData.forEach((element) => {
        newGetUserThird = [...newGetUserThird, ...element?.subordinate_recursive];
      });
      setGetUserThird(newGetUserThird);
      setShowFourthDropdown(newGetUserThird.length > 0);
      setShowFivthDropdown(false);
      setShowSixthDropdown(false);

      setAllUser((prev) => ({
        ...prev,
        SM: null,
        TL: null,
        SO: null,
      }));
      setAllUser((prev) => ({
        ...prev,
        TSM: e,
      }));
    } else {
      form.setFieldsValue({
        user4: [],
        user5: [],
        user6: [],
      });
      setShowFourthDropdown(false);
      setShowFivthDropdown(false);
      setShowSixthDropdown(false);
      setAllUser((prev) => ({
        ...prev,
        TSM: null,
      }));
    }
  };

  const fourthUserOnchange = (e) => {
    if (e) {
      form.setFieldsValue({
        user5: [],
        user6: [],
      });
      setLastSelectedUsers((state) => [...state, e]);
      setGetUserFourth([]);
      let filteredData = [];

      filteredData = [...filteredData, ...getUserThird?.filter((element) => element.id === e)];

      let newGetUserFourth = [];
      filteredData.forEach((element) => {
        newGetUserFourth = [...newGetUserFourth, ...element?.subordinate_recursive];
      });
      setGetUserFourth(newGetUserFourth);
      setShowFivthDropdown(newGetUserFourth?.length > 0);
      setShowSixthDropdown(false);

      setAllUser((prev) => ({
        ...prev,
        TL: null,
        SO: null,
      }));
      setAllUser((prev) => ({
        ...prev,
        SM: e, // Directly set the selected value, not an array
      }));
    } else {
      setUser('');
      // setLastSelectedUsers((state)=>(state?.slice(0,-3)))
      form.setFieldsValue({
        user5: [],
        user6: [],
      });
      setShowFivthDropdown(false);
      setShowSixthDropdown(false);
      setAllUser((prev) => ({
        ...prev,
        SM: null,
      }));
    }
  };

  const fifthUserOnchange = (e) => {
    if (e) {
      form.setFieldsValue({
        user6: [],
      });
      setLastSelectedUsers((state) => [...state, e]);
      setGetUserFivth([]);
      let filteredData = [];

      filteredData = [...filteredData, ...getUserFourth?.filter((element) => element.id === e)];
      let newGetUserFivth = [];
      filteredData.forEach((element) => {
        newGetUserFivth = [...newGetUserFivth, ...element?.subordinate_recursive];
      });
      setGetUserFivth(newGetUserFivth);
      setShowSixthDropdown(newGetUserFivth?.length > 0);
      // setShowSixthDropdown(false);

      setAllUser((prev) => ({
        ...prev,
        SO: null,
      }));
      setAllUser((prev) => ({
        ...prev,
        TL: e, // Directly set the selected value, not an array
      }));
    } else {
      form.setFieldsValue({
        user6: [],
      });
      setShowSixthDropdown(false);
      // setLastSelectedUsers((state)=>(state?.slice(0,state?.lenght-1)))
      setAllUser((prev) => ({
        ...prev,
        TL: null,
      }));
    }
  };

  const sixthUserOnchange = (e) => {
    if (e) {
      setGetUserSixth([]);
      let filteredData = [];
      setLastSelectedUsers((state) => [...state, e]);
      filteredData = [...filteredData, ...getUserFivth?.filter((element) => element.id === e)];
      // filteredData.forEach((element) => {
      //   setGetUserFivth((prev) => [...prev, ...element?.subordinate_recursive]);
      // })

      let newGetUserSixth = [];
      filteredData.forEach((element) => {
        newGetUserSixth = [...newGetUserSixth, ...element?.subordinate_recursive];
      });
      setGetUserSixth(newGetUserSixth);
      // setShowSixthDropdown(newGetUserSixth?.length > 0);

      setAllUser((prev) => ({
        ...prev,
        SO: e, // Directly set the selected value, not an array
      }));
    } else {
      setUser('');
      // setLastSelectedUsers((state)=>(state?.slice(0,-1)))
      setAllUser((prev) => ({
        ...prev,
        SO: null, // Directly set the selected value, not an array
      }));
    }
  };

  const getYears = (launchDate) => {
    const currentYear = new Date().getFullYear();
    const launchYear = dayjs(launchDate, 'DD-MM-YYYY').year(); // Get the year from launchDate
    let years = [];

    // Adjust the range to start from the launch year
    for (let i = Math.max(currentYear - 50, launchYear); i <= currentYear + 50; i++) {
      years.push({ id: i, name: `${i}` });
    }

    return years;
  };
  const years = getYears(launchDate);

  const handleMonthChange = (selectedMonthId) => {
    if (selectedMonthId) {
      setSelectedMonth(selectedMonthId);
      onFiltersChange((prevFilters) => ({
        ...prevFilters,
        month_id: selectedMonthId,
      }));
      dispatch((prevFilters) => ({
        ...prevFilters,
        month_id: selectedMonthId,
      }));
    } else {
      onFiltersChange((prevFilters) => ({
        ...prevFilters,
        month_id: '',
      }));
      dispatch((prevFilters) => ({
        ...prevFilters,
        month_id: '',
      }));
      setSelectedMonth(null);
    }
  };

  const handleYearChange = (selectedYearId) => {
    if (selectedYearId) {
      setSelectedYear(selectedYearId);

      onFiltersChange((prevFilters) => ({
        ...prevFilters,
        year_id: selectedYearId,
      }));
      dispatch((prevFilters) => ({
        ...prevFilters,
        year_id: selectedYearId,
      }));
    } else {
      onFiltersChange((prevFilters) => ({
        ...prevFilters,
        year_id: '',
      }));
      dispatch((prevFilters) => ({
        ...prevFilters,
        year_id: '',
      }));
      setSelectedYear(new Date().getFullYear());
    }
  };

  const handleTerritoryChange = (territoryId) => {
    if (territoryId) {
      onFiltersChange((prevFilters) => ({
        ...prevFilters,
        territory_id: territoryId,
      }));
      setDropdownIds((prevFilters) => ({
        ...prevFilters,
        territory_id: territoryId,
      }));
      dispatch((prevFilters) => ({
        ...prevFilters,
        territory_id: territoryId,
      }));
      // getAreaData(5, territoryId);
    } else {
      onFiltersChange((prevFilters) => ({
        ...prevFilters,
        territory_id: '',
      }));
      setDropdownIds((prevFilters) => ({
        ...prevFilters,
        territory_id: '',
      }));
      dispatch((prevFilters) => ({
        ...prevFilters,
        territory_id: '',
      }));
    }
  };

  return (
    <Card className='filter-card mb-4'>
      <Form form={form} layout='vertical' name='normal_search' className='login-form'>
        <div className='login-input-field'>
          <Row gutter={[16, 16]}>
            {/* User Field */}
            {roleType === 'Exchange Evaluation Team' ? (
              ''
            ) : (
              <>
                {selectUserData?.subordinate_recursive?.length > 0 && (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <InputSelect
                      allowClear
                      rules={[{ required: false, message: 'Please Select Role' }]}
                      name='user_id'
                      placeholder={`${selectUserData?.subordinate_role}`}
                      options={selectUserData?.subordinate_recursive}
                      onSelect={firstUserOnchange}
                      label={`${selectUserData?.subordinate_role}`}
                      optionName={'full_name'}
                      className='form-control-ant'
                      showSearch
                      // mode="multiple"
                      maxTagCount={2}
                    />
                  </Col>
                )}
                {showSecondDropdown && (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <InputSelect
                      // allowClear
                      name='user2'
                      placeholder={`${data[0]}` || 'Select User'}
                      options={getUserOne}
                      onSelect={secondUserOnChange}
                      label={`${data[0]}`}
                      optionName={'full_name'}
                      className='form-control-ant'
                      showSearch
                      // mode="multiple"
                      maxTagCount={2}
                    />
                  </Col>
                )}
                {showThirdDropdown && (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <InputSelect
                      // allowClear
                      name='user3'
                      placeholder={`${data2[0]}`}
                      options={getUserSecond}
                      onSelect={thirdUserOnchange}
                      label={`${data2[0]}`}
                      optionName={'full_name'}
                      className='form-control-ant'
                      showSearch
                      // mode="multiple"
                      maxTagCount={2}
                    />
                  </Col>
                )}
                {showFourthDropdown && (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <InputSelect
                      // allowClear
                      name='user4'
                      placeholder={`${data3[0]}`}
                      options={getUserThird}
                      onSelect={fourthUserOnchange}
                      label={`${data3[0]}`}
                      optionName={'full_name'}
                      className='form-control-ant'
                      showSearch
                      // mode="multiple"
                      maxTagCount={2}
                    />
                  </Col>
                )}
                {showFivthDropdown && (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <InputSelect
                      // allowClear
                      name='user5'
                      placeholder={`${data4[0]}`}
                      options={getUserFourth}
                      onSelect={fifthUserOnchange}
                      label={`${data4[0]}`}
                      optionName={'full_name'}
                      className='form-control-ant'
                      showSearch
                      // mode="multiple"
                      maxTagCount={2}
                    />
                  </Col>
                )}
                {showSixthDropdown && (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <InputSelect
                      // allowClear
                      name='user6'
                      placeholder={`${data5[0]}`}
                      options={getUserFivth}
                      label={`${data5[0]}`}
                      onSelect={sixthUserOnchange}
                      optionName={'full_name'}
                      className='form-control-ant'
                      showSearch
                      // mode="multiple"
                      maxTagCount={2}
                    />
                  </Col>
                )}
              </>
            )}
            {/* Model Dropdown */}
            <Col xl={8} lg={12} md={12} sm={24} xs={24}>
              <InputSelect
                allowClear
                name='select_model'
                placeholder='Model'
                options={vehicleModels}
                label='Model'
                optionName={'model_name'}
                className='form-control-ant'
                showSearch
                onChange={handleModelChange}
              />
            </Col>

            {heading === 'Home' ? (
              <>
                {/* From Date */}
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name='start_date' label='From Date'>
                    <DatePicker
                      format='DD/MM/YYYY'
                      onChange={handleStartDateChange}
                      value={dates.startDate}
                      placeholder='From Date'
                      disabledDate={disablePastDates}
                      defaultValue={dayjs(currentMonthStart)}
                    />
                  </Form.Item>
                </Col>

                {/* To Date */}
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name='end_date' label='To Date'>
                    <DatePicker
                      format='DD/MM/YYYY'
                      onChange={handleEndDateChange}
                      value={dayjs(dates.endDate)}
                      placeholder='To Date'
                      defaultValue={currentMonthEnd}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                {/* Month Field */}
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                  <InputSelect
                    allowClear={true}
                    name='months'
                    placeholder='Select'
                    options={monthData}
                    label='Select Month'
                    optionName={'name'}
                    className='form-control-ant select-month'
                    onSelect={handleMonthChange}
                    defaultValue={new Date().getMonth() + 1}
                  />
                </Col>

                {/* Yeare Field */}
                <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                  <InputSelect
                    allowClear={true}
                    name='years'
                    placeholder='Select Year'
                    options={years}
                    label='Select Year'
                    optionName={'name'}
                    className='form-control-ant select-year'
                    onSelect={handleYearChange}
                    defaultValue={years.find((y) => y.id === new Date().getFullYear())?.id}
                  />
                </Col>
                {/* Territory Field */}
                {roleType === 'Sales Officer' || roleType === 'Sales Manager' || roleType === 'Team Leader' ? (
                  ''
                ) : (
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <InputSelect
                      allowClear={true}
                      name='territory'
                      placeholder='Select Territory'
                      options={territoryData}
                      label='Select Territory'
                      optionName={'name'}
                      className='form-control-ant select-year'
                      onSelect={handleTerritoryChange}
                      mode='multiple'
                    />
                  </Col>
                )}

                {/* Area Field */}
                {/* <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                  <InputSelect
                    allowClear={true}
                    name="area"
                    placeholder="Select Area"
                    options={areaData}
                    label="Select Area"
                    optionName={"name"}
                    className="form-control-ant select-year"
                    onSelect={handleAreaChange}
                    disable={!dropdownIds?.territory_id}
                  />
                </Col> */}
              </>
            )}
          </Row>
        </div>
      </Form>
    </Card>
  );
};

export default Filters;
