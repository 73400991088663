/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Radio,
  Button,
  Space,
  DatePicker,
  TimePicker,
  Typography,
  notification,
} from "antd";
import Loader from "../../../components/common/Loader";
import CityState from "../../../components/common/CityState";
import { API_RESPONSE_CODES } from "../../../utills/constant";
import {
  addHomeVisitDrive,
  generateOTPService,
  resendOTPService,
  verifyOTPService,
} from "../../../services/master-services";
import { fetchLeadDetailsServices } from "../../auth/slice/authSlice";
import NoDataFound from "../../../components/common/NoDataFound";
import { ddmmyyFormatter } from "../../../utills/helpers";

const { TextArea } = Input;
const { Title } = Typography;

const HomeVisit = () => {
  const format = "HH:mm";
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dateFormat = "DD/MM/YYYY";

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [timerId, setTimerId] = useState();
  const [radioData, setRadioData] = useState();
  const [resendOTP, setResendOTP] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(true);
  const [showCountDown, setShowCountDown] = useState(false);
  const [disableOTPField, setDisableOTPField] = useState(true);
  const [disableOTPButton, setDisableOTPButton] = useState(false);
  const [otpButtonText, setOtpButtonText] = useState("Generate OTP");

  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);
  const hasHomeVisitData = leadViewDetails?.home_visit_test_drive?.length > 0;
  const isLeadConverted = leadViewDetails?.lead_status?.name === "Converted" || leadViewDetails?.lead_status?.name === "Lost" || leadViewDetails?.lead_status?.name === "Dropped";

  useEffect(() => {
    dispatch(fetchLeadDetailsServices({ id: id }));
    form.setFieldsValue({
      ...leadViewDetails,
      follow_up_date: leadViewDetails?.next_follow_up_date
        ? dayjs(leadViewDetails?.next_follow_up_date)
        : null,
      state_id: leadViewDetails?.customer?.state?.id,
      city_id: leadViewDetails?.customer?.city?.id,
      address: leadViewDetails?.customer?.address,
    });
  }, []);

  const handleChange = (e) => {
    setRadioData(e.target.value);
  };

  let intervalId;
  const startCountdown = () => {
    setShowCountDown(true);
    setResendOTP(true);
    setTimer(30);
    intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(intervalId);
          setResendOTP(false);
          setShowCountDown(false);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
    setTimerId(intervalId);
    return () => clearInterval(intervalId);
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const reqData = {
      ...values,
      visit_date: values.scheduled_visit_date
        ? dayjs(values.scheduled_visit_date).format("YYYY-MM-DD")
        : null,
      visit_time: values.visit_time
        ? dayjs(values.visit_time).format("HH:mm")
        : null,
      follow_up_date: values.follow_up_date
        ? dayjs(values.follow_up_date).format("YYYY-MM-DD")
        : null,
      lead_id: +id,
    };
    try {
      const response = await addHomeVisitDrive(reqData);
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response.data.message,
        });
        setRadioData("");
        setResendOTP(true);
        form.resetFields();
        setVerifyOTP(false);
        setIsOTPVerified(true);
        setDisableOTPField(true);
        setDisableOTPButton(false);
        setOtpButtonText("Generate OTP");
        dispatch(fetchLeadDetailsServices({ id: id }));
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      form.resetFields();
      setVerifyOTP(false);
      setDisableOTPField(true);
      setDisableOTPButton(false);
      setResendOTP(true);
    } finally {
      setIsLoading(false);
    }
  };

  const disabledTime = () => {
    const current = dayjs();
    return {
      disabledHours: () => [...Array(current.hour()).keys()],
      disabledMinutes: () => [...Array(current.minute()).keys()],
    };
  };

  const handleCancel = () => {
    setRadioData("");
    form.resetFields();
    setResendOTP(true);
    setIsLoading(false);
    setVerifyOTP(false);
    clearInterval(timerId);
    setIsOTPVerified(true);
    setShowCountDown(false);
    setDisableOTPField(true);
    setDisableOTPButton(false);
    setOtpButtonText("Generate OTP");
  };

  const handleOtpChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value?.length <= 4) {
      setOtp(value);
    }
     
  };

  const handleGenerateOTPClick = async () => {
    form
      .validateFields(["type"])
      .then(async () => {
        // If validation succeeds, proceed with OTP generation
        await handleGenerateOTP();
      })
      .catch((errorInfo) => {
        // Handle form validation failure
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleGenerateOTP = async () => {
    const reqData = {
      type: radioData,
      phone_no: leadViewDetails?.customer?.phone_no,
    };
    try {
      setIsLoading(true);
      const response = await generateOTPService(reqData);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        startCountdown();
        setVerifyOTP(true);
        setDisableOTPField(false);
        setDisableOTPButton(true);
        setOtpButtonText("Verify OTP");
        notification.success({
          message: response.data.data,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerfiyOTP = async () => {
    const phone_no = leadViewDetails?.customer?.phone_no;

    try {
      setIsLoading(true);
      const response = await verifyOTPService(otp, phone_no);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        notification.success({
          message: response.data.data,
        });
        setResendOTP(true);
        clearInterval(timerId);
        setShowCountDown(false);
        setIsOTPVerified(false);
        setDisableOTPField(true);
        setDisableOTPButton(true);
        setOtpButtonText("OTP Verified");
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      form.setFieldsValue({
        ...leadViewDetails,
        otp: null,
      });
      setDisableOTPButton(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async () => {
    const phone_no = leadViewDetails?.customer?.phone_no;
    try {
      setIsLoading(true);
      const response = await resendOTPService(phone_no);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        startCountdown();
        notification.success({
          message: response.data.data,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader loading={isLoading} />

      <div className="user-data lead-detail">
        <div className="personal-detail pt-2">
          {roleType !== "Exchange Evaluation Team" && !isLeadConverted ? (
            <div className="user-table-data text-start">
              <Title level={4} className="">
                Home Visit/ Test Drive
              </Title>
              <Form layout="vertical" form={form} onFinish={onSubmit}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please select an option",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={handleChange}
                    value={radioData}
                    defaultValue={radioData}
                  >
                    <Radio value={"home visit"}>Home Visit</Radio>
                    <Radio value={"test drive"}>Test Drive</Radio>
                    {/* <Radio value={"both"}>Both</Radio> */}
                  </Radio.Group>
                </Form.Item>

                {/* <Card className="mt-3"> */}
                <div className="user-left mb-3">
                  <Row gutter={20}>
                    {/* <Col xl={4} lg={4} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Schedule Visit"
                        name="scheduled_visit_date"
                        rules={[
                          {
                            required: true,
                            message: "Please select the scheduled visit date",
                          },
                        ]}
                      >
                        <DatePicker
                          className="ps-2"
                          disabledDate={(current) =>
                            current && current < dayjs().startOf("day")
                          }
                          format={dateFormat}
                          placeholder="Select Date"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={4} lg={4} md={12} sm={24} xs={24}>
                      <Form.Item
                        label="Visit Time"
                        name="visit_time"
                        rules={[
                          {
                            required: true,
                            message: "Please select the visit time",
                          },
                        ]}
                      >
                        <TimePicker
                          className="ps-2"
                          format={format}
                          placeholder="Select Time"
                          // disabledTime={disabledTime}
                        />
                      </Form.Item>
                    </Col> */}

                    <CityState leadViewDetails={leadViewDetails} form={form} />

                    <Col xl={4} lg={4} md={8} sm={24} xs={24}>
                      <Form.Item
                        label="Address"
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter address",
                          },
                        ]}
                      >
                        <Input placeholder="Address" />
                      </Form.Item>
                    </Col>
                    <Col xl={4} lg={4} md={8} sm={24} xs={24}>
                      <Form.Item
                        label="Follow Up Date"
                        name="follow_up_date"
                        rules={[
                          {
                            required: true,
                            message: "Please select follow up date",
                          },
                        ]}
                      >
                        <DatePicker
                          className="ps-2"
                          disabledDate={(current) => {
                            return current < dayjs().startOf('day') || current > dayjs().add(10, 'day').endOf('day');
                          }}
                          format={dateFormat}
                          placeholder="Select Date"
                        />
                      </Form.Item>
                    </Col>

                    <Col xl={4} lg={4} md={8} sm={24} xs={24}>
                      <Form.Item
                        label="Notes"
                        name="notes"
                        rules={[
                          {
                            required: true,
                            message: "Please enter notes",
                          },
                          {
                            max: 300,
                            message: "Maximum length of 300 characters allowed",
                          },
                        ]}
                      >
                        <TextArea placeholder="Notes" />
                      </Form.Item>
                    </Col>
                    <Col xl={4} lg={4} md={8} sm={24} xs={24}>
                      <Form.Item
                        label="OTP"
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: "Please enter OTP",
                          },
                          {
                            pattern: /^\d{1,4}$/,
                            message: "OTP must be 1 to 4 digits",
                          },
                        ]}
                      >
                        <Input
                          value={otp}
                          maxLength={4}
                          placeholder="OTP"
                          disabled={disableOTPField}
                          onChange={handleOtpChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                            if (e.target.value.length === 4) {
                              setDisableOTPButton(false);
                            } else {
                              setDisableOTPButton(true);
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Space>
                        <Button
                          type="primary"
                          disabled={disableOTPButton}
                          className="secondary-button otp-btn"
                          onClick={
                            verifyOTP ? handleVerfiyOTP : handleGenerateOTPClick
                          }
                        >
                          {otpButtonText}
                        </Button>

                        {!resendOTP && (
                          <Button
                            type="primary"
                            disabled={resendOTP}
                            onClick={handleResendOTP}
                            className="secondary-button otp-btn mx-2"
                          >
                            Resend OTP
                          </Button>
                        )}
                      </Space>
                      {showCountDown && (
                        <p>You can resend the OTP in {timer} seconds.</p>
                      )}
                    </Col>
                  </Row>
                </div>
                {/* </Card> */}
                <div className="user-right d-flex align-items-center justify-content-end mt-4">
                  <Button
                    type="primary"
                    ghost
                    danger
                    className="primary-button me-3"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isOTPVerified}
                    type="primary"
                    htmlType="submit"
                    className="secondary-button"
                  >
                    Save
                  </Button>
                </div>
              </Form>
              <div className="h-line mb-2 pt-3">&nbsp;</div>
            </div>
          ) : (
            ""
          )}

          <div className="personal-detail pt-2">
            {hasHomeVisitData ? (
              <>
                <div className="user-left d-flex justify-content-between align-items-center">
                  <Title level={4} className="pe-3">
                    Home Visit/ Test Drive History
                  </Title>
                </div>
                {leadViewDetails?.home_visit_test_drive?.map((lead, index) => (
                  <Card className="lead-stage mb-4" key={index}>
                    <div className="user-table-data text-start">
                      <Form layout="vertical">
                        <div className="user-left my-3">
                          <Title level={4}>
                            {lead?.type === "Home Visit"
                              ? "Home Visit"
                              : lead?.type === "Test Drive"
                              ? "Test Drive"
                              : "Both"}
                          </Title>
                          <Row gutter={20}>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item
                                label="Date:"
                                name={`scheduleVisit-${index}`}
                              >
                                <p>
                                  {lead?.visit_date
                                    ? ddmmyyFormatter(lead?.visit_date)
                                    : "-"}
                                </p>
                              </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item
                                label="Time:"
                                name={`visitTime-${index}`}
                              >
                                <p>
                                  {lead?.visit_time
                                    ? dayjs(lead?.visit_time, "HH:mm").format(
                                        "hh:mm A"
                                      )
                                    : "-"}
                                </p>
                              </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                              <Form.Item
                                label="Follow Up Date:"
                                name={`followUpDate-${index}`}
                              >
                                <p>
                                  {lead?.follow_up_date
                                    ? ddmmyyFormatter(lead?.follow_up_date)
                                    : "-"}
                                </p>
                              </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                              <Form.Item
                                label="Address:"
                                name={`address-${index}`}
                              >
                                <p>{lead.address || "-"}</p>
                              </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                              <Form.Item label="Notes:" name={`notes-${index}`}>
                                {/* <TextArea defaultValue={lead?.notes} readOnly /> */}
                                <p>{lead?.notes || "-"}</p>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </div>
                  </Card>
                ))}
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeVisit;
