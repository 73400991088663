/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space, notification } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/common/Loader';
import QuotationVehicleDetails from './QuotationVehicleDetails';
import PageContentHeader from '../../../components/common/PageContentHeader';
import {
  createQuotationService,
  getInsuranceCostService,
  getInsuranceTypeService,
  getQuotationMasterDataService,
  getRoadTaxCostService,
  getRoadTaxTypeService,
  getRtoService,
  getTermsAndConditionService,
} from '../../../services/master-services';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import { handleTabChange } from '../../auth/slice/authSlice';
import {
  formatIndianCurrency,
  notRequiredPriceValidationFunction,
  priceValidationFunction,
} from '../../../utills/helpers';

const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;

const AddQuotation = ({ setShowAddQuot }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const leadDetails = useSelector((state) => state?.auth?.leadDetails);

  const [rtoData, setRtoData] = useState([]);
  const [tcsValue, setTcsValue] = useState(0);
  const [masterData, setMasterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndCond, setTermsAndCond] = useState();
  const [roadTaxCost, setRoadTaxCost] = useState();
  const [insuranceCost, setInsuranceCost] = useState();
  const [roadTaxTypeData, setRoadTaxTypeData] = useState([]);
  const [insuranceTypeData, setInsuranceTypeData] = useState([]);
  const [excVehicleIds, setExcVehicleIds] = useState({
    make_id: leadDetails?.vehicle_detail?.model?.make_id,
    model_id: leadDetails?.vehicle_detail?.model?.id,
    vehicle_variant_id: leadDetails?.vehicle_detail?.vehicle_variant_id,
    color_type_id: leadDetails?.vehicle_detail?.color_id || 0,
    fuel_type_id: leadDetails?.vehicle_detail?.fuel_type_id,
    seater_id: leadDetails?.vehicle_detail?.seater_id,
  });

  const vehicleDataEntered =
    !excVehicleIds?.make_id ||
    !excVehicleIds?.model_id ||
    !excVehicleIds?.vehicle_variant_id ||
    !excVehicleIds?.color_type_id ||
    !excVehicleIds?.fuel_type_id ||
    !excVehicleIds?.seater_id;

  useEffect(() => {
    if (leadDetails?.customer?.full_name) {
      form.setFieldsValue({
        full_name: leadDetails?.customer?.full_name,
        address: leadDetails?.customer?.address,
        email: leadDetails?.customer?.email,
        phone_no: leadDetails?.customer?.phone_no,
        ew: masterData?.ew || 0,
        tcs: masterData?.tcs || 0,
        fastag: masterData?.fastag || 0,
        rto_cost: masterData?.rto_cost || 0,
        commercial_rebate_discount: 0,
        ex_showroom_price: masterData?.ex_showroom_price || 0,
        logistic_charges: masterData?.logistic_charges || 0,
        tentative_accessories: masterData?.tentative_accessories || 0,
        insurance: 0,
        cost_of_registration: 0,
        amc: 0,
        body_care_3m: 0,
        road_tax: 0,
        other_discount: 0,
      });
      getRoadTaxTypeData();
      getTermsAndCondData();
      getInsuranceTypeData();
      getRTOData(leadDetails?.customer?.state_id);
    }
    form.setFieldsValue({
      // issue_date: dayjs(),
      valid_upto: dayjs().endOf('month'),
    });
  }, []);

  useEffect(() => {
    if (masterData?.ex_showroom_price) {
      let totalInsurance;
      let totalRoadTax;
      if (masterData?.insurance_add_on) {
        totalInsurance = +masterData?.insurance_add_on + +masterData?.insurance_premium + +masterData?.insurance_taxi;
      }
      if (masterData?.road_tax_pvt) {
        totalRoadTax = +masterData?.road_tax_pvt + +masterData?.road_tax_taxi;
      }
      form.setFieldsValue({
        amc: 0,
        body_care_3m: 0,
        ew: masterData?.ew,
        tcs: masterData?.tcs || tcsValue,
        road_tax: totalRoadTax,
        cost_of_registration: 0,
        insurance: totalInsurance,
        fastag: masterData?.fastag,
        commercial_rebate_discount: 0,
        rto_cost: masterData?.rto_cost,
        logistic_charges: masterData?.logistic_charges,
        ex_showroom_price: masterData?.ex_showroom_price,
        tentative_accessories: masterData?.tentative_accessories,
      });
      calculateOnRoadPrice();
    }
  }, [masterData]);

  useEffect(() => {
    calculateOnRoadPrice();
  }, [form, insuranceCost, roadTaxCost]);

  const calculateOnRoadPrice = () => {
    const values = form.getFieldsValue();

    const {
      ex_showroom_price,
      tcs,
      commercial_rebate_discount,
      cost_of_registration,
      ew,
      amc,
      tentative_accessories,
      fastag,
      body_care_3m,
      logistic_charges,
      road_tax,
      other_discount,
      rto_cost,
      insurance,
    } = values;

    const onRoadPrice = [
      ex_showroom_price,
      -commercial_rebate_discount,
      tcs,
      insurance,
      cost_of_registration,
      ew,
      amc,
      tentative_accessories,
      fastag,
      body_care_3m,
      logistic_charges,
      road_tax,
      rto_cost,
    ]?.reduce((acc, curr) => acc + (parseFloat(curr) || 0), 0);

    const totalAmountPayable = [
      ex_showroom_price,
      -commercial_rebate_discount,
      tcs,
      insurance,
      cost_of_registration,
      ew,
      amc,
      tentative_accessories,
      fastag,
      body_care_3m,
      logistic_charges,
      road_tax,
      -other_discount,
      rto_cost,
    ]?.reduce((acc, curr) => acc + (parseFloat(curr) || 0), 0);

    form.setFieldsValue({
      on_road_price: onRoadPrice?.toFixed(2),
      total_amount: totalAmountPayable?.toFixed(2),
    });
  };

  const getVehicleMasterData = async (model_id, variant_id, fuel_type_id, seater_id, color_id) => {
    setIsLoading(true);
    try {
      const res = await getQuotationMasterDataService(model_id, variant_id, fuel_type_id, seater_id, color_id);
      if (res.status === API_RESPONSE_CODES?.OK) {
        setMasterData(res?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getRTOData = async (state_id) => {
    setIsLoading(true);
    try {
      const stateData = await getRtoService(state_id);
      setRtoData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRoadTaxTypeData = async () => {
    setIsLoading(true);
    try {
      const roadTaxData = await getRoadTaxTypeService();
      setRoadTaxTypeData(roadTaxData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getInsuranceTypeData = async () => {
    setIsLoading(true);
    try {
      const insuranceData = await getInsuranceTypeService();
      setInsuranceTypeData(insuranceData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTermsAndCondData = async () => {
    setIsLoading(true);
    try {
      const res = await getTermsAndConditionService('Booking');
      setTermsAndCond(res?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetPrice = () => {
    form.setFieldsValue({
      rto_location_id: leadDetails?.customer?.rto_location_id,
    });

    getVehicleMasterData(
      excVehicleIds?.model_id,
      excVehicleIds?.vehicle_variant_id,
      excVehicleIds?.fuel_type_id,
      excVehicleIds?.seater_id,
      excVehicleIds?.color_type_id
    );
  };

  const handleSendToCustomer = async (val) => {
    val.issue_date = dayjs();
    val.lead_id = leadDetails?.id;
    val.terms_condition = termsAndCond?.conditions;
    try {
      setIsLoading(true);
      const res = await createQuotationService(val);
      if (res.data.status === API_RESPONSE_CODES.OK || res.data.status === API_RESPONSE_CODES.CREATED) {
        form.resetFields();
        notification.success({
          message: res.data.message,
        });
        dispatch(handleTabChange('quotation'));
        setShowAddQuot(false);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setShowAddQuot(false);
  };

  const handleInsuranceTypeChange = async (val) => {
    const payload = {
      vehicle_model_id: excVehicleIds?.model_id,
      vehicle_variant_id: excVehicleIds?.vehicle_variant_id,
      fuel_type_id: excVehicleIds?.fuel_type_id,
      seater_id: excVehicleIds?.seater_id,
      color_id: excVehicleIds?.color_type_id,
      insurance_type_id: val,
    };
    const insuranceCost = await getInsuranceCostService(payload);
    if (insuranceCost.data.status === API_RESPONSE_CODES.OK) {
      setInsuranceCost(insuranceCost?.data?.data);
      form.setFieldsValue({
        insurance: insuranceCost?.data?.data,
      });
    }
  };

  const handleRoadTaxTypeChange = async (val) => {
    const payload = {
      vehicle_model_id: excVehicleIds?.model_id,
      vehicle_variant_id: excVehicleIds?.vehicle_variant_id,
      fuel_type_id: excVehicleIds?.fuel_type_id,
      seater_id: excVehicleIds?.seater_id,
      color_id: excVehicleIds?.color_type_id,
      road_tax_id: val,
    };
    const roadTaxTypeCost = await getRoadTaxCostService(payload);
    if (roadTaxTypeCost.data.status === API_RESPONSE_CODES.OK) {
      setRoadTaxCost(roadTaxTypeCost?.data?.data);
      form.setFieldsValue({
        road_tax: roadTaxTypeCost?.data?.data,
      });
    }
  };

  const handleExShowroomChange = (e) => {
    const exShowroomPrice = parseFloat(e.target.value) || 0;

    // Check if the ex-showroom price is greater than 10 lakhs (1,000,000)
    if (exShowroomPrice > 1000000) {
      const calculatedTCS = (exShowroomPrice * 1) / 100; // 1% of ex showroom price
      setTcsValue(calculatedTCS); // Update the TCS value and round to 2 decimals
      form.setFieldsValue({ tcs: calculatedTCS }); // Set the TCS field value in the form
      calculateOnRoadPrice();
    } else {
      setTcsValue(0); // Set TCS to 0 for values 10 lakhs or less
      form.setFieldsValue({ tcs: 0 }); // Update the TCS field in the form
      calculateOnRoadPrice();
    }
  };

  return (
    <div className='user-data view-quotation-data'>
      <Loader loading={isLoading} />
      <PageContentHeader title='Add Quotation' />
      <Form
        form={form}
        onFinish={handleSendToCustomer}
        layout='vertical'
        onValuesChange={(_, allValues) => {
          calculateOnRoadPrice(allValues);
        }}
      >
        <Card className='user-left mt-3'>
          {/* Issue date and Valid Upto date */}
          <div>
            <Row gutter={20}>
              <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  label='Issue Date:'
                  name='issue_date'
                  rules={[
                    {
                      required: false,
                      message: 'Please select issue date',
                    },
                  ]}
                >
                  <p>{dayjs().format('DD/MM/YYYY')}</p>
                </Form.Item>
              </Col>

              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Form.Item
                  name='valid_upto'
                  label='Valid Up To:'
                  rules={[
                    {
                      required: true,
                      message: 'Please input valid upto date',
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => {
                      // Can not select days before today and after the last day of the current month
                      return current && (current < dayjs().startOf('day') || current > dayjs().endOf('month'));
                    }}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className='h-line'>&nbsp;</div>
          {/* Customer Details */}
          <div>
            <h4 className='mb-3'>Customer Details</h4>
            {/* Name */}
            <Row gutter={[16, 0]} className='mb-3'>
              <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                <Form.Item
                  label='Name:'
                  name='full_name'
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject('Please input your name');
                        }
                        if (!/^[A-Za-z\s]+$/.test(value)) {
                          return Promise.reject('Please input a valid name');
                        }
                        if (/\s{2,}/.test(value)) {
                          return Promise.reject('Name cannot contain two consecutive spaces');
                        }
                        const trimmedValue = value.trim();
                        if (trimmedValue !== value) {
                          return Promise.reject('Name cannot start or end with spaces');
                        }
                        if (value?.length < 3 || value?.length > 30) {
                          return Promise.reject('Name must be between 3 and 30 characters');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder='Name' />
                </Form.Item>
              </Col>

              {/* Address */}
              <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                <Form.Item
                  label='Address:'
                  name='address'
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject('Please input your address');
                        }

                        if (/\s{2,}/.test(value)) {
                          return Promise.reject('Address cannot contain two consecutive spaces');
                        }
                        const trimmedValue = value.trim();
                        if (trimmedValue !== value) {
                          return Promise.reject('Address cannot start or end with spaces');
                        }
                        if (value?.length > 100) {
                          return Promise.reject('Address must not exceed 100 characters');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder='Address' />
                </Form.Item>
              </Col>

              {/* Email */}
              <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                <Form.Item
                  label='Email:'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email id',
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email id',
                    },
                    {
                      validator: (_, value) => {
                        if (value && /@-/.test(value)) {
                          return Promise.reject('Please enter a valid email id');
                        }
                        return Promise.resolve();
                      },
                    },
                    {
                      max: 254,
                      message: 'Email must be at most 254 characters long',
                    },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>
              </Col>

              {/* Contact Number */}
              <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                <Form.Item
                  label='Contact Number:'
                  name='phone_no'
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('Please input your contact number');
                        }
                        if (!/^[0-9]*$/.test(value)) {
                          return Promise.reject('Contact number must be digits only ');
                        }
                        if (value?.length < 10 || value?.length > 10) {
                          return Promise.reject('Please enter a valid 10 digit contact number');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder='Contact Number' />
                </Form.Item>
              </Col>

              {/* Notes */}
              <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                <Form.Item
                  label='Note:'
                  name='notes'
                  rules={[
                    {
                      required: false,
                    },
                    {
                      max: 150,
                      message: 'Maximum length of 150 characters allowed',
                    },
                  ]}
                >
                  <TextArea placeholder='Note' style={{ height: '70px' }} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className='h-line'>&nbsp;</div>

          {/* Vehicle Details */}
          <div>
            <div className='d-flex justify-content-between align-items-center'>
              <h4 className='mb-3'>Vehicle Details</h4>
            </div>
            <Row gutter={[16, 0]} align='middle' className='mb-3'>
              <QuotationVehicleDetails
                form={form}
                setIsLoading={setIsLoading}
                setMasterData={setMasterData}
                excVehicleIds={excVehicleIds}
                setExcVehicleIds={setExcVehicleIds}
              />
              <Button
                type='primary'
                className='secondary-button'
                onClick={() => handleGetPrice()}
                disabled={
                  !excVehicleIds.color_type_id ||
                  !excVehicleIds?.fuel_type_id ||
                  !excVehicleIds?.model_id ||
                  !excVehicleIds?.seater_id ||
                  !excVehicleIds?.vehicle_variant_id
                }
              >
                Get Pricings
              </Button>
            </Row>
          </div>

          {/* Price Details */}
          <div className='h-line'>&nbsp;</div>
          <div className='mb-3'>
            <h4 className='mb-3'>Price Details</h4>
            {/*  Ex-Showroom Price */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>
                Ex-Showroom Price: <span className='asterisk'>*</span>
              </h6>
              <Form.Item name='ex_showroom_price' rules={priceValidationFunction('Ex-Showroom Price')}>
                <Input placeholder='Ex-Showroom Price' onChange={handleExShowroomChange} />
              </Form.Item>
            </div>
            {/* Commercial Rebate/ Discount */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>
                Commercial Rebate/ Discount: <span className='asterisk'>*</span>
              </h6>
              <Form.Item
                name='commercial_rebate_discount'
                label=''
                rules={priceValidationFunction('Commercial Rebate/ Discount')}
              >
                <Input placeholder='Commercial Rebate/ Discount' />
              </Form.Item>
            </div>
            {/*  TCS */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>
                TCS: <span className='asterisk'>*</span>
              </h6>
              <Form.Item name='tcs' label='' rules={priceValidationFunction('TCS')}>
                <Input placeholder='TCS' value={tcsValue} readOnly />
              </Form.Item>
            </div>
            {/* Insurance Type */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>
                Insurance Type: <span className='asterisk'>*</span>
              </h6>
              <Form.Item
                label=''
                name='insurance_type_id'
                rules={[
                  {
                    required: true,
                    message: 'Please select your insurance type ',
                  },
                ]}
              >
                <Select
                  placeholder='Insurance Type'
                  onChange={handleInsuranceTypeChange}
                  disabled={!excVehicleIds.color_type_id}
                >
                  {insuranceTypeData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {/* Insurance */}
            {!isNaN(insuranceCost) && !vehicleDataEntered ? (
              <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                <h6>
                  Insurance: <span className='asterisk'>*</span>
                </h6>
                {/* <Form.Item label='' name='insurance'>
                  <p>{insuranceCost ? formatIndianCurrency(insuranceCost) : '-'}</p>
                </Form.Item> */}
                <Form.Item name='insurance' label='' rules={priceValidationFunction('Insurance')}>
                  <Input placeholder='Insurance' />
                </Form.Item>
              </div>
            ) : (
              ''
            )}
            {/* RTO */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>
                RTO: <span className='asterisk'>*</span>
              </h6>
              <Form.Item
                name='rto_location_id'
                rules={[
                  {
                    required: true,
                    message: 'Please select your RTO ',
                  },
                ]}
              >
                <Select placeholder='RTO'>
                  {rtoData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {/* RTO Cost */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>
                RTO Cost: <span className='asterisk'>*</span>
              </h6>
              <Form.Item name='rto_cost' label='' rules={priceValidationFunction('RTO Cost')}>
                <Input placeholder='RTO Cost' />
              </Form.Item>
            </div>
            {/* Cost of Registration */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>Cost of Registration: </h6>
              <Form.Item
                name='cost_of_registration'
                label=''
                rules={notRequiredPriceValidationFunction('Cost of Registration')}
              >
                <Input placeholder='Cost of Registration' />
              </Form.Item>
            </div>
            {/* Extended Warranty */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>Extended Warranty:</h6>
              <Form.Item name='ew' label='' rules={notRequiredPriceValidationFunction('Extended Warranty')}>
                <Input placeholder='Extended Warranty' />
              </Form.Item>
            </div>
            {/* AMC */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>AMC:</h6>
              <Form.Item name='amc' label='' rules={notRequiredPriceValidationFunction('AMC')}>
                <Input placeholder='AMC' />
              </Form.Item>
            </div>
            {/* Tentative Accessories */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>Tentative Accessories:</h6>
              <Form.Item
                name='tentative_accessories'
                label=''
                rules={notRequiredPriceValidationFunction('Tentative Accessories')}
              >
                <Input placeholder='Tentative Accessories' />
              </Form.Item>
            </div>
            {/* Fastag */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>Fastag:</h6>
              <Form.Item name='fastag' label='' rules={notRequiredPriceValidationFunction('Fastag')}>
                <Input placeholder='Fastag' />
              </Form.Item>
            </div>
            {/* T-Gloss*/}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>T-Gloss:</h6>
              <Form.Item name='body_care_3m' label='' rules={notRequiredPriceValidationFunction('T-Gloss')}>
                <Input placeholder='T-Gloss' />
              </Form.Item>
            </div>
            {/* Other Logistics Charges */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>Other Logistics Charges:</h6>
              <Form.Item
                name='logistic_charges'
                label=''
                rules={notRequiredPriceValidationFunction('Logistics Charge')}
              >
                <Input placeholder='Other Logistics Charges' />
              </Form.Item>
            </div>
            <div className='h-line'>&nbsp;</div>
            {/* Road Tax Type */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>
                Road Tax Type: <span className='asterisk'>*</span>
              </h6>
              <Form.Item
                label=''
                name='road_tax_type_id'
                rules={[
                  {
                    required: true,
                    message: 'Please select your road tax type ',
                  },
                ]}
              >
                <Select
                  onChange={handleRoadTaxTypeChange}
                  placeholder='Road Tax Type'
                  disabled={!excVehicleIds.color_type_id}
                >
                  {roadTaxTypeData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {/* Road Tax */}
            {!isNaN(roadTaxCost) && !vehicleDataEntered ? (
              <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
                <h6>Road Tax:</h6>
                <Form.Item name='road_tax' label='' rules={notRequiredPriceValidationFunction('Road Tax')}>
                  <p>{roadTaxCost ? formatIndianCurrency(roadTaxCost) : '-'}</p>
                </Form.Item>
              </div>
            ) : (
              ''
            )}
            {/* On Road Price */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>On Road Price:</h6>
              <Form.Item
                name='on_road_price'
                label=''
                rules={[
                  {
                    required: false,
                    message: 'Please input on road price ',
                  },
                ]}
              >
                <Input placeholder='On Road Price' />
              </Form.Item>
            </div>
            <div className='h-line'>&nbsp;</div>
            {/* Other Discount */}
            <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
              <h6>Other Discount:</h6>
              <Form.Item name='other_discount' label='' rules={notRequiredPriceValidationFunction('Discount')}>
                <Input placeholder='Other Discount' />
              </Form.Item>
            </div>
          </div>
          <div className='h-line'>&nbsp;</div>
          {/* Total Amount Payable */}
          <div className='price-detail-wrap pricing-input d-flex justify-content-between align-items-baseline'>
            <h6>
              Total Amount Payable: <span className='asterisk'>*</span>
            </h6>
            <Form.Item
              name='total_amount'
              label=''
              rules={[
                {
                  required: true,
                  message: 'Please input total amount payable',
                },
                {
                  validator: (_, value) =>
                    value > 0
                      ? Promise.resolve()
                      : Promise.reject(new Error('The Total Amount Payable should not be zero')),
                },
              ]}
            >
              <Input placeholder='Total Amount Payable' />
            </Form.Item>
          </div>
          {/* Terms and Conditions */}
          <div>
            <Row gutter={[40, 0]} align='start' className='mb-3'>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <h5 className='mb-3'>Terms and Conditions</h5>
                <div className='listing' dangerouslySetInnerHTML={{ __html: termsAndCond?.conditions }} />
              </Col>
            </Row>
          </div>
          {/* Cancel and Send Buttons */}
          <div className='user-right d-flex align-items-center justify-content-end mt-4'>
            <Space size='middle'>
              <Button type='primary' ghost danger className='primary-button' onClick={() => handleCancel()}>
                Cancel
              </Button>

              <Button type='primary' htmlType='submit' className='secondary-button'>
                Send to Customer
              </Button>
            </Space>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default AddQuotation;
