import React from "react";
import { useSelector } from "react-redux";
import { Button, Row, Col, Typography } from "antd";
import QuotationCard from "../../../components/card/QuotationCard";
import NoDataFound from "../../../components/common/NoDataFound";

const { Title } = Typography;

const Quotation = ({setShowQuotation,setQuotationId,setShowAddQuot}) => {
  
  const leadDetails = useSelector((state) => state?.auth?.leadDetails);
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const hasQuotations = leadDetails?.quotations?.length > 0 ? true : false;
  const isExchangeEvaluationTeam = roleType === "Exchange Evaluation Team";
  const isLeadConverted = leadDetails?.lead_status?.name === "Converted" || leadDetails?.lead_status?.name === "Lost" || leadDetails?.lead_status?.name === "Dropped";

  return (
    <>
      <div className="user-data lead-detail">
        {/* <Loader loading={isLoading} /> */}
        <div className="user-left d-flex justify-content-between align-items-center">
          <Title level={4} className="mb-0">
            {hasQuotations || isLeadConverted
              ? "Quotations List"
              : "Add Quotation"}
          </Title>
          {!isExchangeEvaluationTeam && !isLeadConverted ? (
            <div className="user-right">
                <Button type="primary" className="secondary-button" onClick={()=>{setShowAddQuot(true)}}>
                  Add
                </Button>
              {/* <Link to={isAdmin ? "/admin/add-quotation" : "/add-quotation"}>
              </Link> */}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="lead-type-card pt-4">
          <Row gutter={[20, 20]}>
            {hasQuotations ? (
              leadDetails?.quotations?.map((item, index) => (
                <Col key={index} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <QuotationCard setShowQuotation={setShowQuotation} item={item} setQuotationId={setQuotationId}/>
                </Col>
              ))
            ) : (
              <NoDataFound />
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Quotation;
